import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Divider, Stack, Text, Image } from '@chakra-ui/react';

interface FormReviewItemType {
  label?: string;
  value?: string | number;
  icon?: string;
}

interface FormReviewItemNoLinkType extends FormReviewItemType {
  linkLabel?: never;
  linkUrl?: never;
}

interface FormReviewItemWithLinkType extends FormReviewItemType {
  linkLabel: string;
  linkUrl: string;
}

const FormReviewItem: React.FC<
  FormReviewItemNoLinkType | FormReviewItemWithLinkType
> = ({ label, value, icon, linkLabel, linkUrl }) => {
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Text color="text.light">{label}</Text>
        {linkUrl && (
          <RouterLink to={linkUrl}>
            <Text
              color="primary.500"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="md"
              m="0"
            >
              {linkLabel}
            </Text>
          </RouterLink>
        )}
      </Stack>
      <Stack direction="row">
        {icon && <Image src={icon} ps="16px" />}
        <Text color="text.dark" ps={icon ? '' : '16px'}>
          {value}
        </Text>
      </Stack>
      <Divider />
    </Stack>
  );
};
export default FormReviewItem;
