import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { theme } from 'lib/theme';
import GoogleAnalytics from 'lib/GoogleAnalytics';
import ScrollToTop from 'lib/ScrollToTop';

import CustomRoutes from 'routes';

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop>
            <CustomRoutes />
          </ScrollToTop>
          <GoogleAnalytics />
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
  );
};

export default App;
