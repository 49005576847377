import React from 'react';
import { Container, Flex, ContainerProps } from '@chakra-ui/react';

import Footer from 'component/Footer';

interface FullHeightLayoutType {
  center?: true;
  gray?: true;
}

type MainLayoutType = ContainerProps;

export const MainLayout: React.FC<MainLayoutType> = ({
  children,
  ...props
}) => {
  return (
    <Flex direction="column" justifyContent="space-between" minHeight="100vh">
      <Container {...props} pt="12px" width={['100%', 480]} pb="5rem">
        {children}
      </Container>
      <Footer />
    </Flex>
  );
};

export const FullHeightLayout: React.FC<FullHeightLayoutType> = ({
  center,
  gray,
  children
}) => {
  return (
    <Flex
      direction="column"
      minHeight="100vh"
      py="24px"
      backgroundColor={gray ? '' : 'white'}
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent={center ? 'center' : ''}
        width={['100%', 480]}
        flex="1"
      >
        {children}
      </Container>
    </Flex>
  );
};
