import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Button, Flex, Heading, Image } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { errorCodes } from 'lib/errorCodes';

import { FullHeightLayout } from 'component/layout';
import StringToJsx from 'component/StringToJsx';

import RegisterSuccessIcon from 'assets/image/reg_success.svg';
import RegisterFailIcon from 'assets/image/card-reg_fail.svg';

const RegisterComplete: React.FC = () => {
  PageTitle('会員証新規発行');

  const [title, setTitle] = useState<string>();
  const [desc, setDesc] = useState<string>();
  const [searchParams] = useSearchParams();

  const isFail = searchParams.get('fail') === 'true';
  const errCode = searchParams.get('code') as keyof typeof errorCodes;

  useEffect(() => {
    if (errCode) {
      /** エラーコードを参照して、エラーメッセージを設定する */
      setTitle(errorCodes[errCode]?.message);
      setDesc(errorCodes[errCode]?.desc);
    }
  }, []);

  return (
    <FullHeightLayout center>
      {isFail ? (
        <Flex direction="column" flexGrow={1} justifyContent="center">
          <Image src={RegisterFailIcon} mx="auto" mb="16px" />
          <Heading size="md" textAlign="center" mb="24px">
            {title || '認証に失敗しました'}
          </Heading>
          <StringToJsx
            textAlign="center"
            text={desc || '入力いただいた情報に誤りがあります。'}
          />
        </Flex>
      ) : (
        <Flex direction="column" flexGrow={1} justifyContent="center">
          <Image src={RegisterSuccessIcon} mx="auto" mb="16px" />
          <Heading size="md" textAlign="center" mb="40px">
            会員登録が完了しました
          </Heading>
        </Flex>
      )}
      <RouterLink to="/">
        <Button>ホームへ</Button>
      </RouterLink>
    </FullHeightLayout>
  );
};
export default RegisterComplete;
