import React from 'react';
import {
  FormControl,
  FormLabel,
  Text,
  FormControlProps
} from '@chakra-ui/react';

interface FormItemType extends FormControlProps {
  label?: string;
  required?: true;
  caption?: string;
}

const FormItem: React.FC<FormItemType> = ({
  label,
  required,
  caption,
  children,
  ...props
}) => {
  return (
    <FormControl {...props}>
      {label && (
        <FormLabel>
          {label}
          {required && (
            <Text as="span" color="text.danger">
              *
            </Text>
          )}
        </FormLabel>
      )}
      {children}
      {caption && (
        <Text color="text.light" fontSize="sm" pt="6px">
          {caption}
        </Text>
      )}
    </FormControl>
  );
};
export default FormItem;
