import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Input,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  Button,
  Text,
  Image,
  Select
} from '@chakra-ui/react';

import { BirthDateRule, GenderRule, StoreRule } from 'lib/ValidationRules';
import { Gender } from 'lib/populator';
import PageTitle from 'lib/PageTitle';

import {
  GuestRegisterFormValueType,
  StoreRegisterFormValueType
} from 'type/FormValueType';
import { AreaType, StoreType } from 'type/ResponseType';
import { API_SUCCEEDED } from 'lib/constants';
import { getAreaList, getStoreList, registerGuest } from 'api';
import { UtmType } from 'type/UtmType';
import { errorCodes } from 'lib/errorCodes';

import { FullHeightLayout } from 'component/layout';
import FormItem from 'component/FormItem';
import FormError from 'component/FormError';

import ChevRightIcon from 'assets/icons/chev-right.svg';

const GuestRegisterForm: React.FC = () => {
  PageTitle('ゲスト会員証発行');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [areas, setAreas] = useState<Array<AreaType>>();
  const [stores, setStores] = useState<Array<StoreType>>();
  const [selectedAreaId, setSelectedAreaId] = useState<number>();

  const location = useLocation();
  const navigate = useNavigate();
  const validation = yup.object().shape(
    {
      ...BirthDateRule,
      ...GenderRule,
      ...StoreRule
    },
    [['gender', 'gender']]
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<GuestRegisterFormValueType & StoreRegisterFormValueType>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation)
  });

  const onSubmit = handleSubmit((data) => {
    const utm = location.state as UtmType;

    setIsLoading(true);

    registerGuest({ ...data, ...utm }).then((res) => {
      if (res.status === API_SUCCEEDED) {
        // ゲスト登録完了
        setTimeout(() => navigate('/'), 300);
      } else {
        // ゲスト登録失敗
        const code = res.error_code as keyof typeof errorCodes;

        navigate(`/register/complete?fail=true&code=${code}`);
      }
    });
  });

  useEffect(() => {
    getAreaList().then((res) => setAreas(res.data.areas));
    getStoreList(selectedAreaId).then((res) => setStores(res.data.stores));
  }, [selectedAreaId]);

  return (
    <FullHeightLayout>
      <FormError errors={errors} />
      <VStack as="form" spacing="24px" alignItems="start" onSubmit={onSubmit}>
        <FormItem label="生年月日" required>
          <Input
            type="tel"
            placeholder="例：19801012"
            borderColor={errors.birthday ? 'text.danger' : 'inherit'}
            {...register('birthday')}
          />
        </FormItem>
        <FormItem label="性別" required>
          <Controller
            name="gender"
            control={control}
            render={() => (
              <RadioGroup colorScheme="primary">
                <Stack direction="row" spacing="30px" ms="30px">
                  {Gender.map((gender, ind) => (
                    <Radio
                      key={`gender-${ind}`}
                      value={gender.value.toString()}
                      borderColor={errors.gender ? 'text.danger' : 'inherit'}
                      {...register('gender')}
                    >
                      {gender.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
        </FormItem>
        <FormItem label="よく行く店舗（後から変更可能）" required>
          <Select
            placeholder="エリア選択"
            mb="20px"
            onChange={(e) => setSelectedAreaId(parseInt(e.target.value))}
          >
            {areas?.map((area) => (
              <option value={area.id} key={`area-${area.id}`}>
                {area.name}
              </option>
            ))}
          </Select>
          <Select
            placeholder="店舗選択"
            disabled={selectedAreaId ? false : true}
            {...register('store_id')}
          >
            {stores?.map((store) => (
              <option value={store.id} key={`store-${store.id}`}>
                {store.name}
              </option>
            ))}
          </Select>
        </FormItem>
        <FormItem>
          <Button
            isLoading={isLoading}
            type="submit"
            onClick={() => window.scrollTo(0, 0)}
          >
            ゲスト会員証を発行
          </Button>
        </FormItem>
      </VStack>
      <Stack spacing="10px" mt="32px" alignItems="center">
        <Text fontSize="lg" textAlign="center">
          ゲスト登録後、チャージまたはポイントの使用には顧客情報の登録が必要です。
        </Text>
        <RouterLink to="/register">
          <Text
            color="primary.500"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="md"
            m="0"
          >
            <Text as="span" m="0">
              新規会員登録へ
            </Text>
            <Image src={ChevRightIcon} alt="" display="inline-block" ms="8px" />
          </Text>
        </RouterLink>
      </Stack>
    </FullHeightLayout>
  );
};
export default GuestRegisterForm;
