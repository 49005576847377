import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Top from 'page/Top';
import RegisterForm from 'page/RegisterForm';
import RegisterConfirm from 'page/RegisterConfirm';
import RegisterComplete from 'page/RegisterComplete';
import GuestRegisterForm from 'page/GuestRegisterForm';
import CardRegisterForm from 'page/CardRegisterForm';
import CardRegisterComplete from 'page/CardRegisterComplete';
import SelectFavoriteStore from 'page/SelectFavoriteStore';
import History from 'page/History';
import NewsList from 'page/NewsList';
import NewsDetail from 'page/NewsDetail';
import UserMenu from 'page/UserMenu';
import UserCardInfo from 'page/UserCardInfo';
import UserCard from 'page/UserCard';
import UserCardReissue from 'page/UserCardReissue';
import UserEditForm from 'page/UserEditForm';
import UserEditConfirm from 'page/UserEditConfirm';
import Faq from 'page/Faq';
import SessionExpired from 'page/SessionExpired';

const CustomRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Top />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/register/store" element={<SelectFavoriteStore />} />
      <Route path="/register/confirm" element={<RegisterConfirm />} />
      <Route path="/register/complete" element={<RegisterComplete />} />
      <Route path="/guest/register" element={<GuestRegisterForm />} />
      <Route path="/card/register" element={<CardRegisterForm />} />
      <Route
        path="/card/register/complete"
        element={<CardRegisterComplete />}
      />
      <Route path="/history" element={<History />} />
      <Route path="/news" element={<NewsList />} />
      <Route path="/news/:id" element={<NewsDetail />} />
      <Route path="/user/" element={<UserMenu />} />
      <Route path="/user/info" element={<UserCardInfo />} />
      <Route path="/user/card/" element={<UserCard />} />
      <Route path="/user/card/reissue" element={<UserCardReissue />} />
      <Route path="/user/edit" element={<UserEditForm />} />
      <Route path="/user/edit/confirm" element={<UserEditConfirm />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/session-expired" element={<SessionExpired />} />
    </Routes>
  );
};
export default CustomRoutes;
