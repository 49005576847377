import { useEffect, useState } from 'react';

import { getBalanceHistory, getPointHistory, PaginationParamType } from 'api';
import { HistoryItemResponseType } from 'type/ResponseType';

export const useGetHistory = (type: 'prepaid' | 'point', limit: number) => {
  const [histories, setHistories] = useState<HistoryItemResponseType[]>([]);
  const [paging, setPaging] = useState<PaginationParamType>({
    limit: limit,
    offset: 0
  });
  const [isHistoryFinished, setIsHistoryFinished] = useState<boolean>(false);
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(true);

  const processRecords = (records: HistoryItemResponseType[]) => {
    if (records.length) {
      setHistories((prevState) => [...prevState, ...records]);

      // ページネーションを再設定して、リクエストを再送
      setPaging({
        limit: paging.limit,
        offset: paging.offset + paging.limit
      });
    } else {
      setIsLoadingNext(false);
    }
    setIsHistoryFinished(true);
  };

  useEffect(() => {
    if (type === 'point') {
      // ポイント履歴取得
      getPointHistory(paging).then((res) => {
        processRecords(res.data.records);
      });
    }

    if (type === 'prepaid') {
      // 残高履歴取得
      getBalanceHistory(paging).then((res) => {
        processRecords(res.data.records);
      });
    }
  }, [paging]);

  return { histories, isHistoryFinished, isLoadingNext };
};
