import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Text, Divider, Button, Box } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { CurrentUserType } from 'type/ResponseType';
import { formatDate } from 'lib/common';
import { Gender } from 'lib/populator';
import { getCurrentUser } from 'api';

import { MainLayout } from 'component/layout';
import Loader from 'component/Loader';

interface UserCardInfoItemType {
  label: string;
  value?: string;
}

const UserCardInfoItem: React.FC<UserCardInfoItemType> = ({
  label,
  value,
  children
}) => {
  return (
    <>
      <Stack py="12px" px="16px">
        <Text color="text.light">{label}</Text>
        {children && <Stack color="text.dark">{children}</Stack>}
        {value && (
          <Text color="text.dark" ps="16px">
            {value}
          </Text>
        )}
      </Stack>
      <Divider />
    </>
  );
};

const UserCardInfo: React.FC = () => {
  PageTitle('カード情報');

  const [isDone, setIsDone] = useState<boolean>(false);
  const [user, setUser] = useState<CurrentUserType>();
  const [isGuest, setIsGuest] = useState<boolean>();

  useEffect(() => {
    getCurrentUser().then((res) => {
      const data = res.data;
      const gender = Gender.filter((item) => item.text === data.gender);

      data.gender = gender[0].label;
      setIsGuest(data.membership_type === 'guest');
      setUser(data);
      setIsDone(true);
    });
  }, []);

  return (
    <MainLayout>
      {isDone ? (
        <Stack mx="-16px" mt="-16px" pt="16px" backgroundColor="white">
          <UserCardInfoItem label="カード番号" value={user?.card_no} />
          {isGuest ? (
            <UserCardInfoItem label="PIN番号">
              <Box textAlign="center">
                <Text color="text.dark">
                  PIN番号を取得するには本会員登録が必要です
                </Text>
                <RouterLink to="/register?event=upgrade">
                  <Button variant="outline" fontSize="md">
                    顧客情報を登録して本会員になる
                  </Button>
                </RouterLink>
              </Box>
            </UserCardInfoItem>
          ) : (
            <UserCardInfoItem label="PIN番号" value={user?.pin_code} />
          )}
          <UserCardInfoItem label="バーコード番号" value={user?.jan_code} />
          <UserCardInfoItem label="性別" value={user?.gender} />
          <UserCardInfoItem
            label="生年月日"
            value={user?.birthday && formatDate(user?.birthday, false, true)}
          />
        </Stack>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};
export default UserCardInfo;
