import React from 'react';
import { Stack, Alert, UnorderedList, ListItem, Text } from '@chakra-ui/react';
import { FieldErrors } from 'react-hook-form';

const FormError: React.FC<{ errors: FieldErrors }> = ({ errors }) => {
  return (
    <>
      {Object.entries(errors).length > 0 && (
        <Stack mb="24px">
          <Alert status="error" variant="left-accent" py="6px">
            <UnorderedList>
              {Object.entries(errors).map((error, ind) => {
                return (
                  <ListItem
                    key={`error-${ind}`}
                    fontSize="sm"
                    py="2px"
                    color="text.danger"
                  >
                    {error[1].message}
                    {error[1].code && (
                      <Text fontSize="sm" mt="4px" mb="0">
                        エラーコード: {error[1].code}
                      </Text>
                    )}
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Alert>
        </Stack>
      )}
    </>
  );
};
export default FormError;
