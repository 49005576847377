import { atom } from 'recoil';
import {
  BasicFormValueType,
  GuestRegisterFormValueType,
  SelectFavoriteStoreType
} from 'type/FormValueType';

export const UserInfoAtom = atom<
  BasicFormValueType &
    SelectFavoriteStoreType &
    Partial<GuestRegisterFormValueType>
>({
  key: 'userInfo',
  default: {
    first_name: '',
    last_name: '',
    first_name_kana: '',
    last_name_kana: '',
    birthday: undefined,
    gender: undefined,
    tel: '',
    postal_code: '',
    prefecture_id: '',
    city_name: '',
    address_1: '',
    address_2: '',
    store: {
      id: '',
      name: ''
    }
  }
});
