import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'medium',
    width: '100%',
    padding: '14px',
    _disabled: {
      bg: 'dark.50',
      pointerEvents: 'none',
      _hover: {
        bg: 'dark.50'
      }
    }
  },
  variants: {
    solid: {
      bg: 'primary.500',
      color: 'white',
      _hover: {
        bg: 'primary.500',
        _disabled: {
          bg: 'dark.50'
        }
      }
    },
    solidLight: {
      bg: 'primary.50',
      color: 'primary.700'
    },
    solidDark: {
      bg: 'dark.50',
      color: 'dark.500'
    },
    outline: {
      border: '1px solid',
      borderColor: 'primary.700',
      color: 'primary.700'
    },
    outlineDark: {
      border: '1px solid',
      borderColor: '#9A9A9A',
      color: 'dark.500'
    }
  },
  sizes: {
    md: {
      fontSize: 'lg',
      height: 'auto'
    }
  }
};

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'md',
    marginBottom: '8px'
  }
};

export const Link: ComponentStyleConfig = {
  variants: {
    primary: {
      color: 'primary.500',
      _hover: {
        textDecor: 'none'
      }
    },
    dark: {
      color: 'dark.600',
      _hover: {
        textDecor: 'none'
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
};

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '11px',
    fontWeight: 'normal',
    paddingX: '6px',
    paddingY: '3px',
    borderRadius: '3px'
  },
  variants: {
    default: {
      backgroundColor: 'dark.50'
    }
  },
  defaultProps: {
    variant: 'default'
  }
};

export const Tabs: ComponentStyleConfig = {
  variants: {
    pill: {
      tablist: {
        borderRadius: '8px'
      },
      tab: {
        border: '1px solid #9A9A9A',
        _selected: {
          color: 'white',
          backgroundColor: 'primary.500',
          border: 'none'
        },
        _focus: {
          boxShadow: 'none'
        }
      }
    }
  },
  defaultProps: {
    variant: 'pill'
  }
};

export const Accordion: ComponentStyleConfig = {
  variants: {
    default: {
      button: {
        backgroundColor: 'white',
        _hover: {
          backgroundColor: 'white'
        },
        _focus: {
          boxShadow: 'none'
        }
      }
    }
  },
  defaultProps: {
    variant: 'default'
  }
};

export const Heading: ComponentStyleConfig = {
  sizes: {
    md: {
      fontSize: '18px',
      fontWeight: '500'
    }
  }
};
