import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Image,
  Text,
  HStack,
  Link
} from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { openWindow } from 'lib/line';

import { MainLayout } from 'component/layout';

import QuestionIcon from 'assets/icons/faq_ques.svg';

interface FaqItemType {
  title: string;
}

const FaqItem: React.FC<FaqItemType> = ({ title, children }) => {
  return (
    <AccordionItem>
      <AccordionButton alignItems="start" py="20px" px="16px">
        <HStack flex="1" textAlign="left" alignItems="baseline">
          <Image src={QuestionIcon} w="14px" />
          <Text me="12px">{title}</Text>
        </HStack>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel py="16px">{children}</AccordionPanel>
    </AccordionItem>
  );
};

const Faq: React.FC = () => {
  PageTitle('よくある質問');

  return (
    <MainLayout>
      <Accordion mt="-12px" mx="-16px" allowToggle>
        <FaqItem title="新生堂 デジタルハッピーカードとは">
          <Text>
            本ミニアプリ（デジタルハッピーカード）はLINEアプリ上で気軽に新生堂の会員証を新規発行＆利用できるサービスです。
          </Text>
        </FaqItem>
        <FaqItem title="プラスチックのハッピーカードと併用できますか？">
          <Text>
            元々お持ちのプラスチックのハッピーカードを本ミニアプリに連携してご利用の方は、プラスチックとデジタルのハッピーカードを併用することが可能です。
          </Text>
          <Text>
            本ミニアプリでデジタルのハッピーカードを新規発行した方はプラスチックと併用することはできません。
          </Text>
        </FaqItem>
        <FaqItem title="プラスチックのハッピーカードを紛失してしまいました">
          <Text>
            店頭のサービスカウンターで再発行の後、本ミニアプリの「マイページ」→「カード変更」→「新しいカードを連携する」の手順で新しいプラスチックのハッピーカードを連携してください。
          </Text>
        </FaqItem>
        <FaqItem title="デジタルハッピーカードはドラッグでも調剤薬局でも使えますか？">
          <Text>
            ドラッグストア全店・調剤店舗でもご利用頂けます。店頭のQRコードやLINE公式アカウント「新生堂/ハッピー」よりご利用下さい。
          </Text>
        </FaqItem>
        <FaqItem title="会員情報を修正したいです">
          <Text>
            本ミニアプリの「マイページ」→「会員情報編集」の手順で新しい会員情報をすべて入力して上書きすることが可能です。
          </Text>
          <Text>
            ※会員情報の一部だけを修正することはできないため、修正が発生した際は一通りの情報を入力し直していただくようお願いいたします。
            <br />
            ※引越しなどでご利用の店舗が変わった場合は「マイページ」→「よく行く店舗変更」から店舗を変更してください。
          </Text>
        </FaqItem>
        <FaqItem title="ゲスト会員と本会員の違いはなんですか？">
          <Text>
            「ゲスト会員」とは、生年月日と性別以外の会員情報を登録していない簡易的な会員様の呼称です。
            <br />
            ゲスト会員の方は、ポイントを貯めることはできるが使用することはできません。また、チャージ機能を利用することもできません。
          </Text>
          <Text>
            「本会員」とは、名前や住所等の全ての会員情報を登録している会員様の呼称です。
            <br />
            本会員の方は、ポイントの獲得・使用、チャージ機能の利用をすることができます。
          </Text>
          <Text>
            ゲスト会員の方は会員証のトップページの「本会員に登録する」ボタンから会員情報を登録していただくことで本会員の機能が解放されます。
          </Text>
        </FaqItem>
        <FaqItem title="本会員はチャージが出来ますか？">
          <Text>
            本会員登録を行うことで、通常のハッピーカード会員の機能はすべてご利用頂けます。
            <br />
            チャージ入金・チャージ払いなどご利用が可能になります。
          </Text>
        </FaqItem>
        <FaqItem title="新生堂公式アプリに連携できません">
          <Text>
            本ミニアプリの「マイページ」→「カード情報・性別・生年月日」で開かれるページに記載されている、カード番号とPIN番号を利用することで、新生堂公式アプリと連携することが可能です。
          </Text>
          <Text>
            連携に失敗する場合は1日おいてから改めて連携していただきますようお願いいたします。1日おいても連携に失敗する場合は店頭のサービスカウンターまでお越しください。
          </Text>
          <Text>
            また、ゲスト会員の方は新生堂公式アプリに連携することができないため、本会員登録を済ませてから連携に進んでください。
          </Text>
        </FaqItem>
        <FaqItem title="チャージの上限金額を教えてください">
          <Text>チャージの上限金額は50,000円です。</Text>
        </FaqItem>
        <FaqItem title="複数のハッピーカードを統合することはできますか？">
          <Text>
            本ミニアプリで複数のハッピーカードを統合することはできません。
          </Text>
        </FaqItem>
        <FaqItem title="退会する場合はどうしたらいいですか？">
          <Text>
            特別な対応が必要になりますので、店頭のサービスカウンターまでお越しください。
          </Text>
        </FaqItem>
        <FaqItem title="機種変更などにより、別の端末にデジタルのハッピーカードを引き継ぐ場合はどうしたらいいですか？">
          <Text>
            現在ご利用中のLINEアカウントを新しい端末に移行することで、本ミニアプリに登録されているカード情報も自動的に引き継がれます。
          </Text>
          <Text>
            LINEアカウントの移行がうまくいかない、もしくは新しいLINEアカウントで現在ご利用中のデジタルのハッピーカードを利用したい場合は、手続きが必要ですので、店頭のサービスカウンターまでお越しください。
          </Text>
          <Text>
            <Link
              href="#"
              onClick={() => openWindow('https://guide.line.me/ja/migration/')}
            >
              LINEアカウントの移行方法
            </Link>
          </Text>
        </FaqItem>
        <FaqItem title="LINEミニアプリ（デジタルハッピーカード）を次回開くにはどうしたら良いですか？">
          <Text>
            下記のいずれかの方法でデジタルハッピーカードを開くことができます。
          </Text>
          <Text>
            ①
            LINE公式アカウント「新生堂／ハッピー」のトーク画面の「メニュー」にある、「デジタルハッピーカード」というボタンをタップする。
          </Text>
          <Text>
            ②
            LINEアプリの「ホーム」で、「ハッピーカード」と検索し、「サービス」というカテゴリーに表示される「デジタルハッピーカード」をタップする。
          </Text>
          <Text>③ LINEマイカードから「デジタル ハッピーカード」を選択する</Text>
          <Text>④ 店頭に掲示されているQRコードを読み取る。</Text>
          <Text>
            [おすすめ]
            <br />
            LINEのホームタブ機能を利用することで、より本ミニアプリにアクセスしやすくなります。
            <br />
            ホームタブ機能を利用するには、本ミニアプリを起動した状態で、画面右上に表示されているシェアアイコン（「×」の左隣のアイコン）をタップし、「ホームタブに追加」を選択します。
            <br />
            追加されたミニアプリはLINEアプリの「ホーム」に常に表示されるようになり、気軽にアクセスができるようになります。
          </Text>
        </FaqItem>
      </Accordion>
    </MainLayout>
  );
};
export default Faq;
