import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Text, Stack } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { NewsItemResponseType } from 'type/ResponseType';
import { getNewsList } from 'api';

import { MainLayout } from 'component/layout';
import Loader from 'component/Loader';

interface NewsItemType {
  link: string;
  title: string;
  date: string;
}

const NewsItem: React.FC<NewsItemType> = ({ link, title, date }) => {
  return (
    <RouterLink to={link}>
      <Flex
        direction="column"
        backgroundColor="white"
        borderRadius="4px"
        boxShadow={'0px 0px 12px #E2E2E2'}
        p="16px"
      >
        <Text color="text.dark">{title}</Text>
        <Text fontSize="sm" color="text.light" m="0">
          {date}
        </Text>
      </Flex>
    </RouterLink>
  );
};

const NewsList: React.FC = () => {
  PageTitle('お知らせ');

  const [isDone, setIsDone] = useState<boolean>(false);
  const [news, setNews] = useState<Array<NewsItemResponseType>>();

  useEffect(() => {
    getNewsList().then((res) => {
      const data = res.data.notifications;
      setNews(data);
      setIsDone(true);
    });
  }, []);

  return (
    <MainLayout>
      {isDone ? (
        <Stack spacing="12px">
          {news &&
            (news.length ? (
              <>
                {news.map((news) => (
                  <NewsItem
                    key={`news-${news.id}`}
                    link={news.id.toString()}
                    title={news.title}
                    date={news.published_at.split(' ')[0]}
                  />
                ))}
              </>
            ) : (
              <Text>お知らせはありません。</Text>
            ))}
        </Stack>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};
export default NewsList;
