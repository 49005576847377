export const errorCodes = {
  '001': {
    desc: 'カードの新規発行ができませんでした。\nしばらく時間をおいてから登録をお願いします。',
    message: '登録に失敗しました。[001]'
  },
  '002': {
    desc: 'カードの新規発行ができませんでした。\nしばらく時間をおいてから登録をお願いします。',
    message: '登録に失敗しました。[002]'
  },
  '003': {
    desc: '入力内容をご確認ください。[003]',
    message: ''
  },
  '010': {
    desc: 'カード番号・PIN番号が正しいかご確認ください。',
    message: '入力内容に誤りがあります。[010]'
  },
  '011': {
    desc: 'カード番号・PIN番号が正しいかご確認ください。',
    message: '入力内容に誤りがあります。[011]'
  },
  '012': {
    desc: 'カード番号・PIN番号が正しいかご確認ください。',
    message: '入力内容に誤りがあります。[012]'
  },
  '013': {
    desc: 'カード番号・PIN番号が正しいかご確認ください。',
    message: '入力内容に誤りがあります。[013]'
  },
  '014': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[014]'
  },
  '015': {
    desc: '入力内容の誤りが複数回行われたため、一時的にロックされました。しばらく時間をおいてから登録をお願いします。',
    message: '認証に失敗しました。[015]'
  },
  '020': {
    desc: '現在お持ちのカードでは本会員に登録できません。',
    message: ''
  },
  '021': {
    desc: 'カードの新規発行ができませんでした。\nしばらく時間をおいてから登録をお願いします。',
    message: '登録に失敗しました。[021]'
  },
  '022': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[022]'
  },
  '023': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[023]'
  },
  '029': {
    desc: '現在お持ちのカードではカードの再発行はできません。',
    message: ''
  },
  '030': {
    desc: '現在お持ちのカードではカードの再発行はできません。',
    message: ''
  },
  '031': {
    desc: 'カードの新規発行ができませんでした。\nしばらく時間をおいてから登録をお願いします。',
    message: '登録に失敗しました。[031]'
  },
  '032': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[032]'
  },
  '033': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[033]'
  },
  '040': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号が正しいかご確認ください。',
    message: ''
  },
  '041': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号が正しいかご確認ください。',
    message: ''
  },
  '042': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号が正しいかご確認ください。',
    message: ''
  },
  '043': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号が正しいかご確認ください。',
    message: ''
  },
  '044': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[044]'
  },
  '045': {
    desc: '入力内容の誤りが複数回行われたため、一時的にロックされました。しばらく時間をおいてから登録をお願いします。',
    message: '認証に失敗しました。[045]'
  },
  '050': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[050]'
  },
  '051': {
    desc: '現在お持ちのカードでは登録情報修正はできません。',
    message: ''
  },
  '060': {
    desc: '入力内容に誤りがあります。',
    message: ''
  }
};
