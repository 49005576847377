import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FieldErrors } from 'react-hook-form';
import { Button, Stack, Text } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { reissueCard } from 'api';
import { API_SUCCEEDED } from 'lib/constants';
import { errorCodes } from 'lib/errorCodes';

import { FullHeightLayout } from 'component/layout';
import FormError from 'component/FormError';

const UserCardReissue: React.FC = () => {
  PageTitle('カード変更');

  const [errMsg, setErrMsg] = useState<FieldErrors>({});

  const navigate = useNavigate();

  const onReissueClick = () => {
    // カード再発行
    reissueCard().then((res) => {
      if (res.status === API_SUCCEEDED) {
        // 成功
        navigate('/user?event=reissue');
      } else {
        //失敗
        const code = res.error_code as keyof typeof errorCodes;
        const catchError = ['030', '029'];

        if (catchError.includes(code)) {
          /** フォームのエラメッセージとして出す */
          setErrMsg({
            custom: {
              type: 'custom',
              code: `[${code}]`,
              message: errorCodes[code].desc
            }
          });
        } else {
          /** 失敗画面に移動 */
          navigate(`/card/register/complete?fail=true&code=${code}`);
        }
      }
    });
  };

  return (
    <FullHeightLayout>
      <FormError errors={errMsg} />
      <Stack pt="8px" spacing="38px">
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          現在ご利用中のプラスチック及びデジタルのハッピーカードを無効化して、
          <br />
          新しいデジタルカードを発行してもよろしいですか？
        </Text>
        <Text>
          お持ちのプラスチックのハッピーカードもご利用できなくなるのでご注意ください。再発行後も、現在保有しているポイントとプリペイド残高は引き継がれます。
          <br />
          ※プラスチックカードの再発行が必要な方は店舗スタッフにお声かけください。
        </Text>
        <Stack spacing="24px" textAlign="center">
          <Button variant="outlineDark" fontSize="md" onClick={onReissueClick}>
            発行
          </Button>
          <RouterLink to="/user/card">戻る</RouterLink>
        </Stack>
      </Stack>
    </FullHeightLayout>
  );
};
export default UserCardReissue;
