import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Stack, Button, Text } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';

import { BasicFormValueType } from 'type/FormValueType';
import { updateCurrentUser } from 'api';
import { API_SUCCEEDED } from 'lib/constants';
import { Prefecture } from 'lib/populator';
import { errorCodes } from 'lib/errorCodes';

import { FullHeightLayout } from 'component/layout';
import FormReviewItem from 'component/FormReviewItem';

const UsedEditConfirm: React.FC = () => {
  PageTitle('登録情報修正');

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as BasicFormValueType;
  const pref = Prefecture.filter((pref) => pref.id === state.prefecture_id);

  const onSubmit = () => {
    updateCurrentUser(state).then((res) => {
      if (res.status === API_SUCCEEDED) {
        // 成功
        navigate('/user?event=edit');
      } else {
        // 失敗
        const code = res.error_code as keyof typeof errorCodes;

        navigate(`/card/register/complete?fail=true&code=${code}`);
      }
    });
  };

  return (
    <FullHeightLayout>
      <Stack spacing="24px" mb="45px">
        <FormReviewItem
          label="お名前"
          value={`${state.last_name} ${state.first_name}`}
        />
        <FormReviewItem
          label="お名前 (カナ)"
          value={`${state.last_name_kana} ${state.first_name_kana}`}
        />
        <FormReviewItem label="電話番号" value={state.tel} />
        <FormReviewItem label="郵便番号" value={state.postal_code} />
        <FormReviewItem
          label="住所"
          value={
            pref[0].value + state.city_name + state.address_1 + state.address_2
          }
        />
      </Stack>
      <Stack>
        <Button onClick={onSubmit}>修正（上書き）する</Button>
        <RouterLink to="/user/edit" state={state}>
          <Text textAlign="center" mt="20px">
            情報変更へ
          </Text>
        </RouterLink>
      </Stack>
    </FullHeightLayout>
  );
};
export default UsedEditConfirm;
