import React, { useEffect, useState } from 'react';

import { getCurrentUser } from 'api';
import { API_SUCCEEDED } from 'lib/constants';

import Login from './Login';
import Home from './Home';

interface CurrentUserType {
  as: 'guest' | 'main' | 'with_card';
  cardNum: string;
}

const Top: React.FC = () => {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [currUser, setCurrUser] = useState<CurrentUserType>();

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) {
        // 登録済みの場合
        const data = res.data;

        setCurrUser({
          as: data.membership_type,
          cardNum: data.jan_code
        });
        setIsLoggedIn(true);
      }
      setIsDone(true);
    });
  }, []);

  return (
    <>
      {isDone &&
        (isLoggedIn ? (
          currUser && <Home as={currUser?.as} cardNum={currUser?.cardNum} />
        ) : (
          <Login />
        ))}
    </>
  );
};

export default Top;
