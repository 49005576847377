import React from 'react';
import { Icon } from '@chakra-ui/react';
import { useBarcode } from 'react-barcodes';

interface BarcodeType {
  value: string;
  label?: string;
  height?: string | Array<string>;
  showLabel?: boolean;
}

const Barcode: React.FC<BarcodeType> = ({
  value,
  label,
  height,
  showLabel
}) => {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      format: 'EAN13',
      flat: true,
      displayValue: showLabel,
      text: label,
      fontSize: 12,
      font: 'Noto Sans JP, monospace',
      width: 5
    }
  });
  return <Icon ref={inputRef} margin="auto" width="100%" h={height} />;
};
export default Barcode;
