import React, { useState, useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  Box,
  Text,
  VStack,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Radio,
  RadioGroup,
  Image,
  Badge
} from '@chakra-ui/react';

import { UserInfoAtom } from 'recoil/atom';
import { AreaStoreType } from 'type/AreaStoreType';
import { CardRegisterFormValueType } from 'type/FormValueType';
import { UtmType } from 'type/UtmType';
import { API_SUCCEEDED } from 'lib/constants';
import { errorCodes } from 'lib/errorCodes';
import {
  getAreaList,
  getStoreList,
  registerCard,
  updateCurrentUserStore
} from 'api';

import { FullHeightLayout } from 'component/layout';
import FormItem from 'component/FormItem';
import FormError from 'component/FormError';
import Loader from 'component/Loader';

import MapIcon from '../assets/icons/map-icon.svg';

const AreaList: React.FC = ({ children }) => {
  return (
    <Box width="100%">
      <Text ms="16px" mt="24px" mb="12px" fontSize="sm">
        エリア選択
      </Text>
      <FormItem>
        <Accordion allowToggle>{children}</Accordion>
      </FormItem>
    </Box>
  );
};

const Area: React.FC<{ name: string; total: number }> = ({
  name,
  total,
  children
}) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <HStack flex={1} py="5px">
          <Image src={MapIcon} />
          <Text>{name}</Text>
        </HStack>
        <Box>
          <Badge
            backgroundColor="dark.300"
            color="white"
            borderRadius="10px"
            lineHeight={1}
            me="8px"
          >
            {total}
          </Badge>
          <AccordionIcon />
        </Box>
      </AccordionButton>
      <AccordionPanel backgroundColor="white" pb="10px">
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

const SelectFavoriteStore: React.FC = () => {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [areas, setAreas] = useState<Array<AreaStoreType>>([]);
  const [selectedStore, setSelectedStore] = useState<string>('');
  const [errMsg, setErrMsg] = useState<FieldErrors>({});

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const prev = searchParams.get('prev');
  const { register, handleSubmit, setValue } = useForm<{ store: string }>();

  const onSubmit = handleSubmit((data) => {
    const state = location.state;

    // カード連携フロー
    if (prev === 'cardRegister') {
      setIsLoading(true);
      registerCard({
        ...(state as UtmType & CardRegisterFormValueType),
        store_id: data.store
      }).then((res) => {
        if (res.status === API_SUCCEEDED) {
          //成功
          navigate('/card/register/complete');
        } else {
          //失敗
          const code = res.error_code as keyof typeof errorCodes;

          navigate(`/card/register/complete?fail=true&code=${code}`);
        }
      });
    }

    // よくいく店舗更新フロー
    if (prev === 'userEdit') {
      setIsLoading(true);
      updateCurrentUserStore({ store_id: data.store }).then((res) => {
        if (res.status === API_SUCCEEDED) {
          //成功
          navigate('/user?event=storeEdit');
        } else {
          //失敗
          const code = res.error_code as keyof typeof errorCodes;
          const catchError = ['060'];

          if (catchError.includes(code)) {
            /** フォームのエラメッセージとして出す */
            setErrMsg({
              custom: {
                type: 'custom',
                code: `[${code}]`,
                message: errorCodes[code].desc
              }
            });
          } else {
            /** 失敗画面に移動 */
            navigate(`/card/register/complete?fail=true&code=${code}`);
          }
        }
      });
    }

    // 新規登録フロー
    if (prev === 'userRegister') {
      setUserInfo({
        ...userInfo,
        store: {
          id: data.store,
          name: selectedStore
        }
      });
      navigate('/register/confirm', { state: state as UtmType });
    }
  });

  useEffect(() => {
    getAreaList().then((res) => {
      const { areas } = res.data;

      // エリアごとの店舗を取得 Promise[]
      const getStores = areas.map((area: { id: number }) =>
        getStoreList(area.id)
      );

      // Promise[]の中身を順番に行い、取得したエリアと店舗を順番に追加、ステートに保存
      Promise.all(getStores).then((response) => {
        const storesWiseArea: Array<AreaStoreType> = response.map(
          (result, index: number) => ({
            name: areas[index].name,
            stores: result.data.stores
          })
        );
        setAreas(storesWiseArea);
        setIsDone(true);
      });
    });

    // ここの画面に戻った時に前選択した店舗を選択する
    if (userInfo.store.id) {
      setIsValid(true);
      setSelectedStore(userInfo.store.name);
    }
  }, []);

  return (
    <FullHeightLayout>
      <VStack as="form" onSubmit={onSubmit} flex="1" mx="-16px" mb="-24px">
        <Box mb="20px">
          <Text fontSize="xl" textAlign="center">
            よく行く店舗を選択してください。
          </Text>
          <Text textAlign="center" color="text.light">
            後からでも変更可能です
          </Text>
        </Box>
        <Box w="100%" px="16px">
          <FormError errors={errMsg} />
        </Box>
        {isDone ? (
          <VStack
            backgroundColor="dark.100"
            justifyContent="space-between"
            flex="1"
            width="100%"
          >
            {areas.length ? (
              <AreaList>
                {areas?.map((area) => (
                  <Area
                    name={area.name}
                    key={`area-${area.name}`}
                    total={area.stores?.length || 0}
                  >
                    <RadioGroup
                      colorScheme="primary"
                      defaultValue={userInfo.store.id && userInfo.store.id}
                    >
                      {area.stores.map((store) => (
                        <HStack
                          key={`store-${store.id}`}
                          width="100%"
                          position="relative"
                          py="10px"
                          mt="10px"
                        >
                          <Text mb="0" ps="10px">
                            {store.name}
                          </Text>
                          <Radio
                            {...register('store')}
                            value={store.id.toString()}
                            position="absolute"
                            top="0"
                            right="0"
                            justifyContent="end"
                            py="10px"
                            pe="10px"
                            width="100%"
                            onChange={(e) => {
                              setIsValid(true);
                              setSelectedStore(store.name);
                              setValue('store', e.target.value);
                            }}
                          ></Radio>
                        </HStack>
                      ))}
                    </RadioGroup>
                  </Area>
                ))}
              </AreaList>
            ) : (
              <Text pt="16px">店舗がありません。</Text>
            )}
            <FormItem px="16px" pb="24px">
              <Button
                disabled={!isValid || isLoading}
                isLoading={isLoading}
                type="submit"
              >
                次へ
              </Button>
            </FormItem>
          </VStack>
        ) : (
          <Loader />
        )}
      </VStack>
    </FullHeightLayout>
  );
};

export default SelectFavoriteStore;
