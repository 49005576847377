/**
 * 日付のフォーマット変換
 * @param dateStr - Date string
 * @param showTime - (boolean) 時間表示 HH:MM
 * @param isJp - (boolean) 日本語フォーマット
 * @returns "YYYY/MM/DD" || "YYYY/MM/DD HH:MM" ||"YYYY年MM月DD日" || "YYYY年MM月DD日 HH:MM"
 */
export const formatDate = (
  dateStr: string,
  showTime: boolean,
  isJp: boolean
) => {
  const date = new Date(dateStr);
  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, '0');
  const d = date.getDate().toString().padStart(2, '0');
  const h = date.getHours().toString().padStart(2, '0');
  const mn = date.getMinutes().toString().padStart(2, '0');

  let result = isJp ? `${y}年${m}月${d}日` : `${y}/${m}/${d}`;

  if (showTime) {
    result = `${result} ${h}:${mn}`;
  }

  return result;
};

/**
 * 文字列のByte sizeを計算する（SJIS）
 * @param str - string
 * @returns number
 */
export const countByte = (str: string) => {
  let count = 0;

  for (let i = 0; i < str.length; i++) {
    const ucode = str.charCodeAt(i);

    if (
      (ucode >= 0x0 && ucode < 0x81) ||
      ucode === 0xf8f0 ||
      (ucode >= 0xff61 && ucode < 0xffa0) ||
      (ucode >= 0xf8f1 && ucode < 0xf8f4)
    ) {
      count += 1;
    } else {
      count += 2;
    }
  }
  return count;
};
