import React, { useEffect, useState } from 'react';
import {
  Badge,
  HStack,
  Stack,
  Text,
  Box,
  Divider,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { API_SUCCEEDED } from 'lib/constants';
import { getCurrentBalance, getCurrentPoints, getCurrentUser } from 'api';
import { useGetHistory } from 'lib/hooks/history';
import { formatDate } from 'lib/common';

import { MainLayout } from 'component/layout';
import CardBox from 'component/CardBox';
import Loader from 'component/Loader';

interface HistoryItemType {
  isPrepaid: boolean;
  type: string;
  isAdd: boolean;
  date: string;
  store: string;
  amount: number;
  total: number;
  isLast?: boolean;
}

const HistoryItem: React.FC<HistoryItemType> = ({
  isPrepaid,
  type,
  isAdd,
  date,
  store,
  amount,
  total,
  isLast
}) => {
  return (
    <Box>
      <HStack>
        <Badge>{type}</Badge>
        <Text fontSize="sm" color="text.light">
          {date}
        </Text>
      </HStack>
      <HStack alignItems="baseline" justifyContent="space-between">
        <Text m="0">{store}</Text>
        <Text whiteSpace="nowrap" color={isAdd ? 'blue.500' : 'text.danger'}>
          <Text as="span" fontSize="xl" fontWeight="bold">
            {isAdd ? '+' : '-'} {amount.toLocaleString()}
          </Text>
          {isPrepaid ? '円' : 'pt.'}
        </Text>
      </HStack>
      <Text fontSize="sm" color="text.light" textAlign="end" m="0">
        {isPrepaid
          ? `残高：${total.toLocaleString()}円`
          : `保有ポイント：${total.toLocaleString()}pt.`}
      </Text>
      {!isLast && <Divider mt="8px" />}
    </Box>
  );
};

const PrepaidHistory: React.FC<{ balance: number }> = ({ balance }) => {
  const { histories, isHistoryFinished, isLoadingNext } = useGetHistory(
    'prepaid',
    300
  );

  return (
    <Stack spacing="16px">
      <CardBox border>
        <Text m="0">残高</Text>
        <Text fontSize="6xl" textAlign="center" fontWeight="bold" m="0">
          {balance.toLocaleString()}
          <Text as="span" fontSize="5xl" fontWeight="normal">
            円
          </Text>
        </Text>
      </CardBox>
      <CardBox border>
        <Stack spacing="16px">
          {isHistoryFinished && (
            <>
              {histories.map((history, ind) => (
                <HistoryItem
                  isPrepaid={true}
                  type={history.req_class_exp}
                  isAdd={!history.paid_value}
                  date={formatDate(history.reg_date, true, true)}
                  store={history.shop_name}
                  amount={history.paid_value || history.deposit_value}
                  total={history.balance_total}
                  key={`history-${ind}`}
                  isLast={ind + 1 === histories.length}
                />
              ))}
              {isLoadingNext ? (
                <Loader />
              ) : (
                (histories?.length || 0) <= 0 && <Text>履歴がありません。</Text>
              )}
            </>
          )}
        </Stack>
      </CardBox>
    </Stack>
  );
};

const PointHistory: React.FC<{ points: number }> = ({ points }) => {
  const { histories, isHistoryFinished, isLoadingNext } = useGetHistory(
    'point',
    300
  );

  return (
    <Stack spacing="16px">
      <CardBox border>
        <Text m="0">保有ポイント</Text>
        <Text fontSize="6xl" textAlign="center" fontWeight="bold" m="0">
          {points}
          <Text as="span" fontSize="5xl" fontWeight="normal">
            pt
          </Text>
        </Text>
      </CardBox>
      <CardBox border>
        <Stack spacing="16px">
          {isHistoryFinished && (
            <>
              {histories.map((history, ind) => (
                <HistoryItem
                  isPrepaid={false}
                  type={history.req_class_exp}
                  isAdd={!history.paid_point_value}
                  date={formatDate(history.reg_date, true, true)}
                  store={history.shop_name}
                  amount={history.paid_point_value || history.issue_point_value}
                  total={history.balance_point}
                  key={`history-${ind}`}
                  isLast={ind + 1 === histories.length}
                />
              ))}
              {isLoadingNext ? (
                <Loader />
              ) : (
                (histories?.length || 0) <= 0 && <Text>履歴がありません。</Text>
              )}
            </>
          )}
        </Stack>
      </CardBox>
    </Stack>
  );
};

const History: React.FC = () => {
  PageTitle('利用履歴');

  const [isDone, setIsDone] = useState<boolean>(false);
  const [isGuest, setIsGuest] = useState<boolean>();
  const [points, setPoints] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) {
        const data = res.data;

        // ゲストか確認
        setIsGuest(data?.membership_type === 'guest');

        // ポイント取得
        getCurrentPoints().then((res) => {
          setPoints(res.data?.balance_point);

          if (data?.membership_type !== 'guest') {
            // 残高取得
            getCurrentBalance().then((res) => {
              setBalance(res.data?.balance_total);
              setIsDone(true);
            });
          } else {
            setIsDone(true);
          }
        });
      }
    });
  }, []);

  return (
    <MainLayout>
      {isDone ? (
        <>
          {isGuest ? (
            <PointHistory points={points} />
          ) : (
            <Tabs isLazy>
              <TabList mb="12px">
                <Tab w="50%" borderRadius="8px 0 0 8px" py="12px">
                  チャージ
                </Tab>
                <Tab w="50%" borderRadius="0 8px 8px 0" py="12px">
                  ポイント
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <PrepaidHistory balance={balance} />
                </TabPanel>
                <TabPanel p="0">
                  <PointHistory points={points} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};

export default History;
