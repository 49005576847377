import React from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { UnorderedList, ListItem, Text, Image, Link } from '@chakra-ui/react';
import { openWindow } from 'lib/line';

import HomeOnIcon from 'assets/icons/footer_home_on.svg';
import HomeOffIcon from 'assets/icons/footer_home_off.svg';
import NewsOnIcon from 'assets/icons/footer_news_on.svg';
import NewsOffIcon from 'assets/icons/footer_news_off.svg';
import UserOnIcon from 'assets/icons/footer_user_on.svg';
import UserOffIcon from 'assets/icons/footer_user_off.svg';
import SendIcon from 'assets/icons/footer_send.svg';

interface FooterItemType {
  link: string;
  icon: string;
  label: string;
  active: boolean;
}

const FooterItem: React.FC<FooterItemType> = ({
  link,
  icon,
  label,
  active
}) => {
  return (
    <RouterLink to={link} style={{ flex: '1 1 0' }}>
      <ListItem
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt="12px"
      >
        <Image src={icon} w="24px" h="24px" alt="" />
        <Text fontSize="sm" color={active ? 'text.dark' : ''}>
          {label}
        </Text>
      </ListItem>
    </RouterLink>
  );
};

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  const active = {
    home: false,
    news: false,
    user: false
  };

  if (matchPath('/', pathname) || matchPath('/history/*', pathname)) {
    active.home = true;
  }

  if (matchPath('/news/*', pathname)) {
    active.news = true;
  }

  if (matchPath('/user/*', pathname) || matchPath('/faq/*', pathname)) {
    active.user = true;
  }

  return (
    <UnorderedList
      position="fixed"
      bottom="0"
      width="100%"
      backgroundColor="white"
      styleType="none"
      display="flex"
      justifyContent="space-around"
      boxShadow="0px -2px 10px #E2E2E2"
      m="0"
    >
      <FooterItem
        link="/"
        icon={active.home ? HomeOnIcon : HomeOffIcon}
        label="会員証"
        active={active.home}
      />
      <FooterItem
        link="/news"
        icon={active.news ? NewsOnIcon : NewsOffIcon}
        label="お知らせ"
        active={active.news}
      />
      <FooterItem
        link="/user"
        icon={active.user ? UserOnIcon : UserOffIcon}
        label="マイページ"
        active={active.user}
      />
      <Link
        href="#"
        onClick={() => openWindow('https://liff.line.me/1657120330-qvRQVvjA')}
        flex="1 1 0"
      >
        <ListItem
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt="12px"
        >
          <Image src={SendIcon} w="24px" h="24px" alt="処方箋送信" />
          <Text fontSize="sm" color="#68C6CA">
            処方箋送信
          </Text>
        </ListItem>
      </Link>
    </UnorderedList>
  );
};
export default Footer;
