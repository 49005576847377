import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { VStack, Input, Button } from '@chakra-ui/react';

import { CardNumberRule, PinNumberRule } from 'lib/ValidationRules';
import PageTitle from 'lib/PageTitle';

import { CardRegisterFormValueType } from 'type/FormValueType';
import { UtmType } from 'type/UtmType';
import { API_SUCCEEDED } from 'lib/constants';
import { errorCodes } from 'lib/errorCodes';
import { reconnectCard } from 'api';

import { FullHeightLayout } from 'component/layout';
import FormItem from 'component/FormItem';
import FormError from 'component/FormError';

const CardRegisterForm: React.FC = () => {
  PageTitle('カード連携');

  const [errMsg, setErrMsg] = useState<FieldErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const event = searchParams.get('event');
  const validation = yup.object().shape({
    ...CardNumberRule,
    ...PinNumberRule
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CardRegisterFormValueType>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation)
  });

  const onSubmit = handleSubmit((data) => {
    if (event === 'reconnect') {
      // 再連携の場合
      setIsLoading(true);
      reconnectCard(data).then((res) => {
        if (res.status === API_SUCCEEDED) {
          // 成功
          navigate('/card/register/complete');
        } else {
          // 失敗
          const code = res.error_code as keyof typeof errorCodes;
          const catchError = ['039', '040', '041', '042', '043'];

          if (catchError.includes(code)) {
            /** フォームのエラメッセージとして出す */
            setErrMsg({
              custom: {
                type: 'custom',
                code: `[${code}]`,
                message: errorCodes[code].desc
              }
            });
            setIsLoading(false);
          } else {
            /** 失敗画面に移動 */
            navigate(`/card/register/complete?fail=true&code=${code}`);
          }
        }
      });
    } else {
      // カード連携フロー
      const utm = location.state as UtmType;
      navigate('/register/store?prev=cardRegister', {
        state: { ...data, ...utm }
      });
    }
  });

  return (
    <FullHeightLayout>
      <FormError errors={{ ...errors, ...errMsg }} />
      <VStack as="form" spacing="24px" alignItems="start" onSubmit={onSubmit}>
        <FormItem
          label="カード番号"
          caption="※ポイント移行のため、正しいカード番号を入力してください。"
          required
        >
          <Input
            type="tel"
            placeholder="例：1234 4567 7890 0000"
            borderColor={errors.card_no ? 'text.danger' : 'inherit'}
            {...register('card_no')}
          />
        </FormItem>
        <FormItem label="PIN番号" required>
          <Input
            type="tel"
            placeholder="例：123456"
            borderColor={errors.pin_code ? 'text.danger' : 'inherit'}
            {...register('pin_code')}
          />
        </FormItem>
        <FormItem>
          <Button
            mt="16px"
            isLoading={isLoading}
            type="submit"
            onClick={() => window.scrollTo(0, 0)}
          >
            {event === 'reconnect' ? '次へ' : '連携する'}
          </Button>
        </FormItem>
      </VStack>
    </FullHeightLayout>
  );
};
export default CardRegisterForm;
