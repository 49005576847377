import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button
} from '@chakra-ui/react';

interface DialogModalType {
  isOpen: boolean;
  onClose: () => void;
  buttonLabel?: string;
  buttonLink?: string;
  onButtonClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const DialogModal: React.FC<DialogModalType> = ({
  isOpen,
  onClose,
  buttonLabel,
  buttonLink,
  onButtonClick,
  children
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx="32px">
        <ModalBody py="32px">{children}</ModalBody>
        <ModalFooter p="0">
          <Button
            variant="solidDark"
            onClick={onClose}
            borderRadius="0 0 0 6px"
            flex={1}
            _focus={{ boxShadow: 'none' }}
          >
            キャンセル
          </Button>
          {buttonLink ? (
            <RouterLink to={buttonLink || ''} onClick={onButtonClick}>
              <Button
                borderRadius="0 0 6px 0"
                _focus={{ boxShadow: 'none' }}
                flex={1}
              >
                {buttonLabel}
              </Button>
            </RouterLink>
          ) : (
            <Button
              borderRadius="0 0 6px 0"
              _focus={{ boxShadow: 'none' }}
              onClick={() => onButtonClick}
              flex={1}
            >
              {buttonLabel}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default DialogModal;
