import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Flex,
  Box,
  Image,
  Text,
  Stack,
  Button,
  VStack,
  Divider
} from '@chakra-ui/react';

import { getCurrentBalance, getCurrentPoints } from 'api';

import { MainLayout } from 'component/layout';
import CardBox from 'component/CardBox';
import Barcode from 'component/Barcode';
import Loader from 'component/Loader';

import CardBg from 'assets/image/card_bg.svg';
import Logo from 'assets/image/card_logo.svg';
import ReloadIcon from 'assets/icons/reload.svg';

interface HomeType {
  as: 'guest' | 'main' | 'with_card';
  cardNum: string;
}

interface CardBalanceType {
  isGuest: boolean;
  points: number;
  balance: number;
  onReload: () => void;
}

const Card: React.FC<{ cardNum: string }> = ({ cardNum }) => {
  return (
    <Flex
      direction="column"
      alignItems="start"
      justifyContent="space-between"
      backgroundColor="primary.500"
      borderRadius="8px"
      backgroundImage={CardBg}
      boxShadow="0px 0px 12px #E2E2E2"
      w="100%"
      h={['180px', '220px']}
      p="12px"
    >
      <Image src={Logo} w={['50%', '40%']} alt="logo" />
      <Box
        backgroundColor="white"
        borderRadius="8px"
        py="10px"
        px="4px"
        w="100%"
      >
        <Barcode value={cardNum} height={['60px', '80px']} />
      </Box>
    </Flex>
  );
};

const CardBalance: React.FC<CardBalanceType> = ({
  isGuest,
  points,
  balance,
  onReload
}) => {
  return (
    <CardBox shadow>
      {isGuest ? (
        <Box>
          <Text>チャージ残高</Text>
          <Text color="text.light">
            チャージのご利用には顧客情報の登録が必要です。
          </Text>
        </Box>
      ) : (
        <Flex justifyContent="space-between" alignItems="center">
          <Text>チャージ残高</Text>
          <Text color="text.dark" fontSize="3xl">
            {balance.toLocaleString()}
            <Text as="span"> 円</Text>
          </Text>
        </Flex>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Text>保有ポイント</Text>
        <Text color="text.dark" fontSize="3xl">
          {points.toLocaleString()}
          <Text as="span"> pt.</Text>
        </Text>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center">
        <Button
          leftIcon={<img src={ReloadIcon} />}
          colorScheme="black"
          variant="link"
          width="fit-content"
          onClick={onReload}
        >
          再読み込み
        </Button>
      </Flex>
      <Box mb="-16px" mx="-16px" mt="16px">
        <Divider />
        <RouterLink to="history">
          <Text
            color="primary.500"
            display="block"
            textAlign="center"
            borderRadius="0 0 8px 8px"
            px="24px"
            py="10px"
            m="0"
          >
            利用履歴
          </Text>
        </RouterLink>
      </Box>
    </CardBox>
  );
};

const Home: React.FC<HomeType> = ({ as, cardNum }) => {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [points, setPoints] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);

  const handleReload = () => location.reload();

  useEffect(() => {
    // NOTE: Fetch balance and point separately with serial processing
    getCurrentPoints()
      .then((resPoints) => {
        setPoints(resPoints.data.balance_point);
        getCurrentBalance()
          .then((resBalance) => {
            setBalance(resBalance.data.balance_total);
            setIsDone(true);
          })
          .catch(() => {
            // NOTE: In case of the error on either fetching balance or point, show error message
            setHasError(true);
            setIsDone(true);
          });
      })
      .catch(() => {
        setHasError(true);
        setIsDone(true);
      });
  }, []);

  return (
    <MainLayout>
      {isDone ? (
        <Stack spacing="12px">
          {cardNum && <Card cardNum={cardNum} />}
          {hasError ? (
            <CardBox shadow>
              <VStack alignItems="center">
                <Text textAlign="center">
                  会員情報取得中にて
                  <br />
                  エラーが発生しました
                </Text>
                <Button
                  leftIcon={<img src={ReloadIcon} />}
                  colorScheme="black"
                  variant="link"
                  width="fit-content"
                  onClick={handleReload}
                >
                  再読み込み
                </Button>
              </VStack>
            </CardBox>
          ) : (
            <>
              {as === 'guest' && (
                <CardBox shadow>
                  <Text textAlign="center">
                    ポイントのご利用には
                    <br />
                    お客様情報の登録が必要です。
                  </Text>
                  <RouterLink to="/register?event=upgrade">
                    <Button mt="16px">本会員に登録する</Button>
                  </RouterLink>
                </CardBox>
              )}
              <CardBalance
                isGuest={as === 'guest'}
                points={points || 0}
                balance={balance || 0}
                onReload={handleReload}
              />
            </>
          )}
        </Stack>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};

export default Home;
