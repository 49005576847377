import React from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Container, Button, Image, Text, Divider } from '@chakra-ui/react';

import { FullHeightLayout } from 'component/layout';

import TopLogo from 'assets/image/top_logo.png';

const Login: React.FC = () => {
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source') || '';
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const utmContent = searchParams.get('utm_content') || '';
  const utmMedium = searchParams.get('utm_medium') || '';

  return (
    <FullHeightLayout center>
      <Container mb="2em" p="0">
        <Image src={TopLogo} alt="" mx="auto" />
      </Container>
      <Container p="0">
        <Text textAlign="center">すぐポイントを貯めたい方は</Text>
        <RouterLink
          to="/guest/register"
          state={{
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            utm_medium: utmMedium
          }}
        >
          <Button mb="24px" variant="solidLight">
            ゲスト会員として登録する
          </Button>
        </RouterLink>
        <RouterLink
          to="/register"
          state={{
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            utm_medium: utmMedium
          }}
        >
          <Button mb="14px">新規会員登録</Button>
        </RouterLink>
        <Container position="relative" mb="14px" textAlign="center">
          <Divider position="absolute" top="50%" left={0} />
          <Text
            textAlign="center"
            fontSize="sm"
            backgroundColor="white"
            display="inline-block"
            color="text.lightest"
            position="relative"
            zIndex={1}
            px={18}
          >
            または
          </Text>
        </Container>
        <RouterLink
          to="/card/register"
          state={{
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_content: utmContent,
            utm_medium: utmMedium
          }}
        >
          <Button variant="outline">すでにハッピーカードをお持ちの方</Button>
        </RouterLink>
      </Container>
    </FullHeightLayout>
  );
};

export default Login;
