import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Divider, Stack, Text, Image, HStack } from '@chakra-ui/react';

import { openWindow } from 'lib/line';
import PageTitle from 'lib/PageTitle';
import { getCurrentUser } from 'api';
import { APP_LINK } from 'lib/populator';
import { API_SUCCEEDED } from 'lib/constants';

import { MainLayout } from 'component/layout';
import CustomToast from 'component/Toast';
import Loader from 'component/Loader';

import MapIcon from '../assets/icons/map-icon.svg';
import ChevronIcon from 'assets/icons/chev-right_light.svg';
import FaqIcon from 'assets/icons/user_faq.svg';
import CardInfoIcon from 'assets/icons/user_card-info.svg';
import CardReissueIcon from 'assets/icons/user_card-reissue.svg';
import UserEditIcon from 'assets/icons/user_edit.svg';
import ExternalLinkIcon from 'assets/icons/external-link.svg';

interface UserMenuItemType {
  icon?: string;
  label: string;
  link: string;
  external?: true;
}

const UserMenuItem: React.FC<UserMenuItemType> = ({
  icon,
  label,
  link,
  external
}) => {
  return (
    <RouterLink
      to={external ? '#' : link}
      onClick={() => external && openWindow(link)}
    >
      <HStack backgroundColor="white" p="16px">
        {external ? (
          <>
            <Text m="0">{label}</Text>
            <Image src={ExternalLinkIcon} />
          </>
        ) : (
          <HStack w="100%" justifyContent="space-between">
            <HStack>
              <Image src={icon} />
              <Text m="0">{label}</Text>
            </HStack>
            <Image src={ChevronIcon} />
          </HStack>
        )}
      </HStack>
      <Divider />
    </RouterLink>
  );
};

const UserMenu: React.FC = () => {
  PageTitle('マイページ');

  const [isDone, setIsDone] = useState<boolean>(false);
  const [isGuest, setIsGuest] = useState<boolean>();
  const [isCardUser, setIsCardUser] = useState<boolean>();

  const [searchParams, setSearchParams] = useSearchParams();
  const event = searchParams.get('event');

  useEffect(() => {
    if (event === 'reissue') {
      CustomToast('カード発行しました。');
      setSearchParams({});
    }

    if (event === 'edit') {
      CustomToast('登録情報の修正が完了しました。');
      setSearchParams({});
    }

    if (event === 'storeEdit') {
      CustomToast('よく行く店舗を変更しました。');
      setSearchParams({});
    }

    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) {
        // ゲスト会員か確認
        setIsGuest(res.data.membership_type === 'guest');
        // カード連携会員か確認
        setIsCardUser(res.data.membership_type === 'with_card');
      }
      setIsDone(true);
    });
  }, []);

  return (
    <MainLayout>
      {isDone ? (
        <Stack mt="-12px" mx="-16px" spacing="0">
          <UserMenuItem link="/faq" icon={FaqIcon} label="よくある質問" />
          <UserMenuItem
            link="/register/store?prev=userEdit"
            icon={MapIcon}
            label="よく行く店舗変更"
          />
          <UserMenuItem
            link="info"
            icon={CardInfoIcon}
            label="カード情報・性別・生年月日"
          />
          {isCardUser && (
            <UserMenuItem
              link="card"
              icon={CardReissueIcon}
              label="カード変更"
            />
          )}
          {!isGuest && (
            <UserMenuItem
              link="edit"
              icon={UserEditIcon}
              label="会員情報編集"
            />
          )}
          <UserMenuItem link={APP_LINK} label="アプリダウンロード" external />
        </Stack>
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};
export default UserMenu;
