import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Flex, Image, Heading, Button, Text } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { errorCodes } from 'lib/errorCodes';

import StringToJsx from 'component/StringToJsx';
import { FullHeightLayout } from 'component/layout';

import RegisterSuccessIcon from 'assets/image/card-reg_success.svg';
import RegisterFailIcon from 'assets/image/card-reg_fail.svg';

interface CardFailType {
  title?: string;
  desc?: string;
}

const CardSuccess: React.VFC = () => {
  return (
    <>
      <Flex direction="column" flexGrow={1} justifyContent="center">
        <Image src={RegisterSuccessIcon} mx="auto" mb="16px" />
        <Heading size="md" textAlign="center" mb="40px">
          登録が完了しました
        </Heading>
      </Flex>
      <RouterLink to="/">
        <Button>ホームへ</Button>
      </RouterLink>
    </>
  );
};

const CardFail: React.FC<CardFailType> = ({ title, desc }) => {
  return (
    <>
      <Flex direction="column" flexGrow={1} justifyContent="center">
        <Image src={RegisterFailIcon} mx="auto" mb="16px" />
        <Heading size="md" textAlign="center" mb="24px">
          {title || '認証に失敗しました'}
        </Heading>
        <Text textAlign="center">
          <StringToJsx text={desc || '入力いただいた情報に誤りがあります。'} />
        </Text>
      </Flex>
      <RouterLink to="/">
        <Button>戻る</Button>
      </RouterLink>
    </>
  );
};

const CardRegisterComplete: React.FC = () => {
  PageTitle('カード連携');

  const [title, setTitle] = useState<string>();
  const [desc, setDesc] = useState<string>();
  const [searchParams] = useSearchParams();

  const isFail = searchParams.get('fail') === 'true';
  const errCode = searchParams.get('code') as keyof typeof errorCodes;

  useEffect(() => {
    if (errCode) {
      /** エラーコードを参照して、エラーメッセージを設定する */
      setTitle(errorCodes[errCode]?.message);
      setDesc(errorCodes[errCode]?.desc);
    }
  }, []);

  return (
    <FullHeightLayout center>
      {isFail ? <CardFail title={title} desc={desc} /> : <CardSuccess />}
    </FullHeightLayout>
  );
};
export default CardRegisterComplete;
