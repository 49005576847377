import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import postal_code from 'japan-postal-code';
import { VStack, HStack, Input, Text, Select, Button } from '@chakra-ui/react';

import {
  AddressRule,
  NameKanaRule,
  NameRule,
  TelRule,
  ZipcodeRule
} from 'lib/ValidationRules';
import { Prefecture } from 'lib/populator';
import PageTitle from 'lib/PageTitle';

import { BasicFormValueType } from 'type/FormValueType';

import { FullHeightLayout } from 'component/layout';
import FormItem from 'component/FormItem';
import FormError from 'component/FormError';

const UserEditForm: React.FC = () => {
  PageTitle('会員情報編集');

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as BasicFormValueType;
  const validation = yup.object().shape({
    ...NameRule,
    ...NameKanaRule,
    ...TelRule,
    ...ZipcodeRule,
    ...AddressRule
  });

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<BasicFormValueType>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation)
  });

  const onSubmit = handleSubmit((data: BasicFormValueType) => {
    navigate('confirm', {
      state: { ...data }
    });
  });

  const onPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    postal_code.get(target.value, function (address) {
      const pref = Prefecture.filter(
        (pref) => pref.value === address.prefecture
      );
      setValue('prefecture_id', pref[0].id);
      setValue('city_name', address.city);
      setValue('address_1', address.area);
    });
  };

  useEffect(() => {
    reset(state);
  }, []);

  return (
    <FullHeightLayout>
      <FormError errors={errors} />
      <VStack as="form" spacing="24px" alignItems="start" onSubmit={onSubmit}>
        <Text color="text.dark" textAlign="center" mb="32px">
          登録情報を上書きします。下記、変更の無い内容も含め全てご入力ください。
        </Text>
        <HStack spacing="12px">
          <FormItem label="姓" required>
            <Input
              type="text"
              placeholder="例：新規"
              borderColor={errors.last_name ? 'text.danger' : 'inherit'}
              {...register('last_name')}
            />
          </FormItem>
          <FormItem label="名" required>
            <Input
              type="text"
              placeholder="例：登録"
              borderColor={errors.first_name ? 'text.danger' : 'inherit'}
              {...register('first_name')}
            />
          </FormItem>
        </HStack>
        <HStack spacing="12px">
          <FormItem label="セイ (カナ)" required>
            <Input
              type="text"
              placeholder="例：シンキ"
              borderColor={errors.last_name_kana ? 'text.danger' : 'inherit'}
              {...register('last_name_kana')}
            />
          </FormItem>
          <FormItem label="メイ (カナ)" required>
            <Input
              type="text"
              placeholder="例：トウロク"
              borderColor={errors.first_name_kana ? 'text.danger' : 'inherit'}
              {...register('first_name_kana')}
            />
          </FormItem>
        </HStack>
        <FormItem
          label="電話番号"
          caption="※ハイフン（-）は不要です。"
          required
        >
          <Input
            type="tel"
            placeholder="例：08000001234"
            borderColor={errors.tel ? 'text.danger' : 'inherit'}
            {...register('tel')}
          />
        </FormItem>
        <FormItem
          label="郵便番号"
          caption="※ハイフン（-）は不要です。"
          required
        >
          <Input
            type="tel"
            placeholder="例：1690001"
            borderColor={errors.postal_code ? 'text.danger' : 'inherit'}
            {...register('postal_code')}
            onChange={onPostalCodeChange}
          />
        </FormItem>
        <FormItem label="都道府県" width="50%" required>
          <Select
            placeholder="選択"
            borderColor={errors.prefecture_id ? 'text.danger' : 'inherit'}
            {...register('prefecture_id')}
          >
            {Prefecture.map((pref) => (
              <option value={pref.id} key={`pref-${pref.id}`}>
                {pref.value}
              </option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="市区名" required>
          <Input
            type="text"
            placeholder="例：〇〇区"
            borderColor={errors.city_name ? 'text.danger' : 'inherit'}
            {...register('city_name')}
          />
        </FormItem>
        <FormItem label="町名" required>
          <Input
            type="text"
            placeholder="例：〇〇〇町"
            borderColor={errors.address_1 ? 'text.danger' : 'inherit'}
            {...register('address_1')}
          />
        </FormItem>
        <FormItem label="番地・アパート・マンション名">
          <Input
            type="text"
            placeholder="例：1-2-23 〇〇〇〇マンション120号"
            borderColor={errors.address_2 ? 'text.danger' : 'inherit'}
            {...register('address_2')}
          />
        </FormItem>
        <FormItem>
          <Button mt="16px" type="submit" onClick={() => window.scrollTo(0, 0)}>
            次へ
          </Button>
        </FormItem>
      </VStack>
    </FullHeightLayout>
  );
};
export default UserEditForm;
