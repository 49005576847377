import React, { useState } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { FieldErrors } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { UserInfoAtom } from 'recoil/atom';
import { Gender, Prefecture } from 'lib/populator';
import PageTitle from 'lib/PageTitle';
import { API_SUCCEEDED } from 'lib/constants';
import { UtmType } from 'type/UtmType';
import { registerRegular, upgradeMembership } from 'api';
import { errorCodes } from 'lib/errorCodes';

import { FullHeightLayout } from 'component/layout';
import FormReviewItem from 'component/FormReviewItem';
import FormError from 'component/FormError';

import MapIcon from '../assets/icons/map-icon.svg';

const RegisterConfirm: React.FC = () => {
  PageTitle('会員証新規発行');

  const [errMsg, setErrMsg] = useState<FieldErrors>({});
  const [searchParams] = useSearchParams();
  const [userInfo] = useRecoilState(UserInfoAtom);

  const location = useLocation();
  const navigate = useNavigate();
  const event = searchParams.get('event');
  const gender = Gender.filter((gender) => gender.value === userInfo.gender);
  const pref = Prefecture.filter((pref) => pref.id === userInfo.prefecture_id);
  const isUpgrade = event === 'upgrade';

  const onSubmit = () => {
    const registerUser = () => {
      if (isUpgrade) {
        // ゲストから本会員切り替えフロー
        return upgradeMembership(userInfo);
      } else {
        // 新規登録フロー
        const utm = location.state as UtmType;

        return registerRegular({
          ...userInfo,
          store_id: userInfo.store.id,
          ...utm
        });
      }
    };

    registerUser().then((res) => {
      if (res.status === API_SUCCEEDED) {
        // 成功
        navigate('/register/complete');
      } else {
        // 失敗
        const code = res.error_code as keyof typeof errorCodes;
        const catchError = ['020'];

        if (catchError.includes(code)) {
          /** フォームのエラメッセージとして出す */
          setErrMsg({
            custom: {
              type: 'custom',
              code: `[${code}]`,
              message: errorCodes[code].desc
            }
          });
        } else {
          /** 失敗画面に移動 */
          navigate(`/register/complete?fail=true&code=${code}`);
        }
      }
    });
  };

  return (
    <FullHeightLayout>
      <Heading size="md" textAlign="center" mb="40px">
        会員情報入力確認
      </Heading>
      <Stack spacing="24px" mb="45px">
        <FormError errors={errMsg} />
        <FormReviewItem
          label="お名前"
          value={`${userInfo.last_name} ${userInfo.first_name}`}
        />
        <FormReviewItem
          label="お名前 (カナ)"
          value={`${userInfo.last_name_kana} ${userInfo.first_name_kana}`}
        />
        {userInfo.birthday && (
          <FormReviewItem label="生年月日" value={userInfo.birthday} />
        )}
        {gender[0] && <FormReviewItem label="性別" value={gender[0].label} />}
        <FormReviewItem label="電話番号" value={userInfo.tel} />
        <FormReviewItem label="郵便番号" value={userInfo.postal_code} />
        <FormReviewItem
          label="住所"
          value={
            pref[0].value +
            userInfo.city_name +
            userInfo.address_1 +
            userInfo.address_2
          }
        />
        {!isUpgrade && (
          <FormReviewItem
            label="よく行く店舗"
            value={userInfo.store.name}
            icon={MapIcon}
            linkUrl="/register/store?prev=userRegister"
            linkLabel="変更"
          />
        )}
      </Stack>
      <Stack>
        <Button onClick={onSubmit}>登録</Button>
        <RouterLink to={isUpgrade ? '/register?event=upgrade' : '/register'}>
          <Text textAlign="center" mt="20px">
            情報変更へ
          </Text>
        </RouterLink>
      </Stack>
    </FullHeightLayout>
  );
};
export default RegisterConfirm;
