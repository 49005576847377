import { extendTheme } from '@chakra-ui/react';

import {
  Button,
  Text,
  Link,
  Heading,
  Badge,
  Tabs,
  Accordion
} from './component';

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: '#333333',
        bg: '#E5E5E5'
      }
    }
  },
  fonts: {
    body: 'Noto Sans JP, system-ui, sans-serif'
  },
  fontSizes: {
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '34px',
    '6xl': '40px'
  },
  components: { Button, Text, Link, Heading, Badge, Tabs, Accordion },
  colors: {
    text: {
      primary: '#EE7A00',
      dark: '#191919',
      light: '#707070',
      lightest: '#9A9A9A',
      danger: '#E70012'
    },
    primary: {
      50: '#FF60063D',
      500: '#EE7A00',
      600: '#FF6006',
      700: '#C34200'
    },
    dark: {
      50: '#C1C1C1',
      100: '#F2F2F2',
      300: '#707070',
      500: '#333333',
      600: '#191919'
    }
  }
});
