import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Text,
  Button,
  Stack,
  Divider,
  Container,
  useDisclosure
} from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';

import { MainLayout } from 'component/layout';
import DialogModal from 'component/Modal';

const UserCard: React.FC = () => {
  PageTitle('カード変更');

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <MainLayout backgroundColor="white" mt="0">
      <Stack>
        <Box textAlign="center" my="18px">
          <Text color="text.dark" mb="12px">
            デジタルカードを発行する方はこちら
          </Text>
          <RouterLink to="reissue">
            <Button variant="outline" fontSize="md">
              新しいデジタルカードを発行
            </Button>
          </RouterLink>
        </Box>
        <Container position="relative" textAlign="center">
          <Divider position="absolute" top="50%" left={0} />
          <Text
            textAlign="center"
            fontSize="sm"
            backgroundColor="white"
            display="inline-block"
            color="text.lightest"
            position="relative"
            zIndex={1}
            px={18}
          >
            または
          </Text>
        </Container>
        <Box textAlign="center" my="18px">
          <Text color="text.dark" mb="12px">
            店舗で発行したカードをお持ちの方はこちら
          </Text>
          <RouterLink to="#" onClick={onOpen}>
            <Button variant="outlineDark" fontSize="md">
              新しいカードを連携する
            </Button>
          </RouterLink>
        </Box>
      </Stack>
      <DialogModal
        isOpen={isOpen}
        onClose={onClose}
        buttonLink="/card/register?event=reconnect"
        buttonLabel="カード番号変更"
      >
        <Text fontSize="xl" mb="16px" textAlign="center">
          カード番号の変更後利用履歴
        </Text>
        <Text m="0">
          ※
          カード番号の変更、または、ゲストから本会員への登録を行った場合変更以前の履歴はご確認いただけません。
        </Text>
      </DialogModal>
    </MainLayout>
  );
};
export default UserCard;
