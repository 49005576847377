import React from 'react';
import { Flex } from '@chakra-ui/react';

interface CardBoxType {
  shadow?: true;
  border?: true;
}

const CardBox: React.FC<CardBoxType> = ({ shadow, border, children }) => {
  return (
    <Flex
      direction="column"
      backgroundColor="white"
      borderRadius="8px"
      boxShadow={shadow ? '0px 0px 12px #E2E2E2' : ''}
      border={border ? '1px solid #D8D8D8' : ''}
      p="16px"
    >
      {children}
    </Flex>
  );
};
export default CardBox;
