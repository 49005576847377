import * as yup from 'yup';
import postal_code from 'japan-postal-code';
import { toHalfwidthKana, toAscii } from 'japanese-string-utils';

import { countByte } from './common';

const REGEX = {
  allFullWidth:
    /^[\u3040-\u309f\u30a0-\u30ff\uff10-\uff19\uff08-\uff65\u4e00-\u9faf\uf900-\ufaff]*$/,
  kanaFullWidth: /^[^\u3040-\u309f\u4e00-\u9faf\uff08-\uff65a-zA-Z]+$/,
  spChar: /^[^\u2212"',<>&*]+$/,
  spCharWithSpace: /^[^\u2212"',<>&\s*]+$/
};

export const NameRule = {
  last_name: yup
    .string()
    .required('「姓」を入力してください')
    .matches(REGEX.allFullWidth, '「姓」は全角で入力してください')
    .matches(
      REGEX.spCharWithSpace,
      '「姓」にスペースと特殊文字は使用できません'
    )
    .when('first_name', (first_name, schema) => {
      return schema.test({
        test: (last_name: string) => countByte(first_name + last_name) <= 23,
        message: '「姓」と「名」の文字数がオーバーしています'
      });
    }),
  first_name: yup
    .string()
    .required('「名」を入力してください')
    .matches(REGEX.allFullWidth, '「名」は全角で入力してください')
    .matches(
      REGEX.spCharWithSpace,
      '「名」にスペースと特殊文字は使用できません'
    )
};

export const NameKanaRule = {
  last_name_kana: yup
    .string()
    .required('「セイ（カナ）」を入力してください')
    .matches(
      REGEX.kanaFullWidth,
      '「セイ（カナ）」は全角カナで入力してください'
    )
    .matches(
      REGEX.spCharWithSpace,
      '「セイ（カナ）」にスペースと特殊文字は使用できません'
    )
    .when('first_name_kana', (first_name_kana, schema) => {
      return schema.test({
        test: (last_name_kana: string) => {
          const firstNameAscii = toAscii(first_name_kana);
          const lastNameAscii = toAscii(last_name_kana);
          const firstNameHalfWidth = toHalfwidthKana(firstNameAscii);
          const lastNameHalfWidth = toHalfwidthKana(lastNameAscii);
          return countByte(lastNameHalfWidth + firstNameHalfWidth) <= 23;
        },
        message:
          '「セイ（カナ）」と「メイ（カナ）」の文字数がオーバーしています'
      });
    }),
  first_name_kana: yup
    .string()
    .required('「メイ（カナ）」を入力してください')
    .matches(
      REGEX.kanaFullWidth,
      '「メイ（カナ）」は全角カナで入力してください'
    )
    .matches(
      REGEX.spCharWithSpace,
      '「メイ（カナ）」にスペースと特殊文字は使用できません'
    )
};

export const GenderRule = {
  gender: yup
    .number()
    .nullable()
    .when('gender', {
      is: (gender: number) => gender !== undefined,
      then: (schema) => schema.required('「性別」を選択してください')
    })
};

export const BirthDateRule = {
  birthday: yup.string().when('gender', {
    is: (birthday: number) => birthday !== undefined,
    then: (schema) =>
      schema
        .required('「生年月日」を入力してください')
        .max(8, '正しい生年月日を入力してください')
        .min(8, '正しい生年月日を入力してください')
  })
};

export const TelRule = {
  tel: yup
    .string()
    .required('「電話番号」を入力してください')
    .max(11, '正しい電話番号を入力してください')
    .min(10, '正しい電話番号を入力してください')
    .matches(/^[0-9]*$/, '正しい電話番号を入力してください')
};

export const ZipcodeRule = {
  postal_code: yup
    .string()
    .required('「郵便番号」を入力してください')
    .min(7, '正しい郵便番号を入力してください')
    .max(7, '正しい郵便番号を入力してください')
    .test('valid-zip', '正しい郵便番号を入力してください', (val) => {
      let isValid = false;
      postal_code.get(val as string, function (address) {
        isValid = Object.keys(address).length !== 0;
      });
      return isValid;
    })
};

export const AddressRule = {
  prefecture_id: yup.string().required('「都道府県」を選択してください'),
  city_name: yup
    .string()
    .required('「市区名」を入力してください')
    .matches(REGEX.spChar, '「市区名」に特殊文字は使用できません')
    .test(
      'check-byte',
      '「市区名」の文字数がオーバーしています',
      (val) => countByte(val as string) <= 36
    ),
  address_1: yup
    .string()
    .required('「町名」を入力してください')
    .matches(REGEX.spChar, '「町名」に特殊文字は使用できません')
    .test(
      'check-byte',
      '「町名」の文字数がオーバーしています',
      (val) => countByte(val as string) <= 36
    ),
  address_2: yup
    .string()
    .required('「番地・アパート・マンション名」を入力してください')
    .matches(
      REGEX.spChar,
      '「番地・アパート・マンション名」に特殊文字は使用できません'
    )
    .test(
      'check-byte',
      '「番地・アパート・マンション名」の文字数がオーバーしています',
      (val) => countByte(val as string) <= 36
    )
};

export const CardNumberRule = {
  card_no: yup
    .string()
    .required('「カード番号」を入力してください')
    .min(16, '正しいカード番号を入力してください')
    .max(16, '正しいカード番号を入力してください')
    .matches(/^88[0-9]*$/, '正しいカード番号を入力してください')
};

export const PinNumberRule = {
  pin_code: yup
    .string()
    .required('「PIN番号」を入力してください')
    .min(6, '正しいPIN番号を入力してください')
    .max(6, '正しいPIN番号を入力してください')
    .matches(/^[0-9]*$/, '正しいPIN番号を入力してください')
};

export const StoreRule = {
  store_id: yup.string().required('「よく行く店舗」を選択してください')
};

export const TermsRule = {
  terms: yup
    .boolean()
    .oneOf([true], 'ハッピーカードご利用約款の同意にチェックを入れてください')
};

export const PolicyRule = {
  policy: yup
    .boolean()
    .oneOf([true], 'プライバシーポリシーの同意にチェックを入れてください')
};
