import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Text } from '@chakra-ui/react';

import PageTitle from 'lib/PageTitle';
import { formatDate } from 'lib/common';
import { NewsItemDetailResponseType } from 'type/ResponseType';
import { getNewsDetails } from 'api';

import { FullHeightLayout } from 'component/layout';
import StringToJsx from 'component/StringToJsx';

const FullBanner: React.FC<{ src: string }> = ({ src }) => {
  return (
    <Box mt="-24px" mx="-16px">
      <Image src={src} w="100%" />
    </Box>
  );
};

const NewsDetail: React.FC = () => {
  PageTitle('お知らせ');

  const [news, setNews] = useState<NewsItemDetailResponseType>();

  const { id } = useParams();

  useEffect(() => {
    getNewsDetails(id as string).then((res) => setNews(res.data));
  }, []);

  return (
    <FullHeightLayout>
      {news?.image_file_name && <FullBanner src={news?.image_file_name} />}
      <Box mt="28px">
        <Text fontSize="xl">{news?.title}</Text>
        <Text color="text.light">
          {formatDate(news?.published_at || '', false, true)}
        </Text>
        <Box mt="24px">
          <StringToJsx text={news?.description || ''} />
        </Box>
      </Box>
    </FullHeightLayout>
  );
};
export default NewsDetail;
