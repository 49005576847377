import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import postal_code from 'japan-postal-code';
import {
  Input,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  HStack,
  VStack,
  Text,
  Stack,
  Button,
  Link
} from '@chakra-ui/react';

import {
  AddressRule,
  BirthDateRule,
  GenderRule,
  NameKanaRule,
  NameRule,
  PolicyRule,
  TelRule,
  TermsRule,
  ZipcodeRule
} from 'lib/ValidationRules';
import { Gender, Prefecture } from 'lib/populator';
import PageTitle from 'lib/PageTitle';
import { RegisterFormValueType } from 'type/FormValueType';
import { UtmType } from 'type/UtmType';
import { UserInfoAtom } from 'recoil/atom';
import { openWindow } from 'lib/line';

import { FullHeightLayout } from 'component/layout';
import FormItem from 'component/FormItem';
import FormError from 'component/FormError';

const RegisterForm: React.FC = () => {
  PageTitle('会員証新規発行');

  const [searchParams] = useSearchParams();
  const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

  const location = useLocation();
  const navigate = useNavigate();
  const event = searchParams.get('event');
  const validation = yup.object().shape(
    {
      ...NameRule,
      ...NameKanaRule,
      ...BirthDateRule,
      ...GenderRule,
      ...TelRule,
      ...ZipcodeRule,
      ...AddressRule,
      ...TermsRule,
      ...PolicyRule
    },
    [['gender', 'gender']]
  );

  const {
    register,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterFormValueType>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    resolver: yupResolver(validation)
  });

  const onSubmit = handleSubmit((data: RegisterFormValueType) => {
    // termsとpolicyのステートを保存しない
    const { terms, policy, ...rest } = data; // eslint-disable-line @typescript-eslint/no-unused-vars
    setUserInfo({ ...rest });

    if (event === 'upgrade') {
      // ゲストから本会員切り替えフロー
      navigate('confirm?event=upgrade');
    } else {
      // 新規登録フロー
      const utm = location.state as UtmType;
      navigate('store?prev=userRegister', { state: utm });
    }
  });

  const onPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    postal_code.get(target.value, function (address) {
      const pref = Prefecture.filter(
        (pref) => pref.value === address.prefecture
      );
      setValue('prefecture_id', pref[0].id);
      setValue('city_name', address.city);
      setValue('address_1', address.area);
    });
  };

  useEffect(() => {
    reset(userInfo);
  }, []);

  return (
    <FullHeightLayout>
      <FormError errors={errors} />
      <VStack as="form" spacing="24px" alignItems="start" onSubmit={onSubmit}>
        <HStack spacing="12px">
          <FormItem label="姓" required>
            <Input
              type="text"
              placeholder="例：新規"
              borderColor={errors.last_name ? 'text.danger' : 'inherit'}
              {...register('last_name')}
            />
          </FormItem>
          <FormItem label="名" required>
            <Input
              type="text"
              placeholder="例：登録"
              borderColor={errors.first_name ? 'text.danger' : 'inherit'}
              {...register('first_name')}
            />
          </FormItem>
        </HStack>
        <HStack spacing="12px">
          <FormItem label="セイ (カナ)" required>
            <Input
              type="text"
              placeholder="例：シンキ"
              borderColor={errors.last_name_kana ? 'text.danger' : 'inherit'}
              {...register('last_name_kana')}
            />
          </FormItem>
          <FormItem label="メイ (カナ)" required>
            <Input
              type="text"
              placeholder="例：トウロク"
              borderColor={errors.first_name_kana ? 'text.danger' : 'inherit'}
              {...register('first_name_kana')}
            />
          </FormItem>
        </HStack>
        {event !== 'upgrade' && (
          <>
            <FormItem label="生年月日" required>
              <Input
                type="tel"
                placeholder="例：19801012"
                borderColor={errors.birthday ? 'text.danger' : 'inherit'}
                {...register('birthday')}
              />
            </FormItem>
            <FormItem label="性別" required>
              <Controller
                name="gender"
                control={control}
                render={() => (
                  <RadioGroup
                    colorScheme="primary"
                    defaultValue={userInfo.gender && userInfo.gender.toString()}
                  >
                    <Stack direction="row" spacing="30px" ms="30px">
                      {Gender.map((gender, ind) => (
                        <Radio
                          key={`gender-${ind}`}
                          value={gender.value.toString()}
                          borderColor={
                            errors.gender ? 'text.danger' : 'inherit'
                          }
                          {...register('gender')}
                        >
                          {gender.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormItem>
          </>
        )}
        <FormItem
          label="電話番号"
          caption="※ハイフン（-）は不要です。"
          required
        >
          <Input
            type="tel"
            placeholder="例：08000001234"
            borderColor={errors.tel ? 'text.danger' : 'inherit'}
            {...register('tel')}
          />
        </FormItem>
        <FormItem
          label="郵便番号"
          caption="※ハイフン（-）は不要です。"
          required
        >
          <Input
            type="tel"
            placeholder="例：1690001"
            borderColor={errors.postal_code ? 'text.danger' : 'inherit'}
            {...register('postal_code')}
            onChange={onPostalCodeChange}
          />
        </FormItem>
        <FormItem label="都道府県" width="50%" required>
          <Select
            placeholder="選択"
            borderColor={errors.prefecture_id ? 'text.danger' : 'inherit'}
            {...register('prefecture_id')}
          >
            {Prefecture.map((pref) => (
              <option value={pref.id} key={`pref-${pref.id}`}>
                {pref.value}
              </option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="市区名" required>
          <Input
            type="text"
            placeholder="例：〇〇区"
            borderColor={errors.city_name ? 'text.danger' : 'inherit'}
            {...register('city_name')}
          />
        </FormItem>
        <FormItem label="町名" required>
          <Input
            type="text"
            placeholder="例：〇〇〇町"
            borderColor={errors.address_1 ? 'text.danger' : 'inherit'}
            {...register('address_1')}
          />
        </FormItem>
        <FormItem label="番地・アパート・マンション名" required>
          <Input
            type="text"
            placeholder="例：1-2-23 〇〇〇〇マンション120号"
            borderColor={errors.address_2 ? 'text.danger' : 'inherit'}
            {...register('address_2')}
          />
        </FormItem>
        <Stack spacing="10px">
          <HStack>
            <Checkbox
              colorScheme="primary"
              borderColor={errors.terms ? 'text.danger' : 'inherit'}
              {...register('terms')}
            ></Checkbox>
            <Text fontSize="sm" style={{ touchAction: 'none' }}>
              <Link
                onClick={() =>
                  openWindow('https://sinseido-co.jp/happycard/terms.php')
                }
              >
                ハッピーカードご利用約款
              </Link>
              を確認して同意します。
            </Text>
          </HStack>
          <HStack>
            <Checkbox
              colorScheme="primary"
              borderColor={errors.policy ? 'text.danger' : 'inherit'}
              {...register('policy')}
            ></Checkbox>
            <Text fontSize="sm" style={{ touchAction: 'none' }}>
              <Link
                onClick={() => openWindow('https://sinseido-co.jp/privacy.php')}
              >
                新生堂薬局プライバシーポリシー
              </Link>
              を確認して同意します。
            </Text>
          </HStack>
        </Stack>
        <FormItem>
          <Button type="submit" onClick={() => window.scrollTo(0, 0)}>
            次へ
          </Button>
        </FormItem>
      </VStack>
    </FullHeightLayout>
  );
};

export default RegisterForm;
